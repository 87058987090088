import React from "react"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Svg404 from "../svg/404"

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 1rem;
  margin: 0 auto;

  & h1,
  p {
    text-align: center;
  }
`

export default class NotFoundPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.pageStartRef = React.createRef()
  }

  componentDidMount() {
    if (this.pageStartRef.current) {
      this.pageStartRef.current.scrollIntoView({ behaviour: "smooth" })
    }
    window.Sentry.captureException(`404: ${window.location.href}`)
  }

  render() {
    return (
      <Layout>
        <Seo title="404: Not found" />
        <CopyContainer ref={this.pageStartRef}>
          <Svg404 size="100" fill="white" />
          <h1>Not found</h1>
          <p>Whoops.. this page doesn't exist.</p>
          <p>
            Don't worry, Sing Smart Studios has been notified about the problem.
          </p>
        </CopyContainer>
      </Layout>
    )
  }
}
