import React from "react"

export default class Svg404 extends React.PureComponent {
  render() {
    const { fill, size, style } = this.props
    return (
      <svg
        fill={fill || "black"}
        height={size || "512pt"}
        width={size || "512pt"}
        viewBox="0 -21 512 512"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path d="m255.558594 106.140625c-11.386719 0-20.648438 9.269531-20.648438 20.660156v71.300781c0 11.386719 9.261719 20.648438 20.648438 20.648438 11.390625 0 20.660156-9.261719 20.660156-20.648438v-71.300781c0-11.390625-9.269531-20.660156-20.660156-20.660156zm0 0" />
        <path d="m464.898438 0h-417.796876c-25.972656 0-47.101562 21.128906-47.101562 47.101562v277.796876h512v-277.796876c0-25.972656-21.128906-47.101562-47.101562-47.101562zm-283.679688 233.75c0 8.289062-6.71875 15-15 15-8.289062 0-15-6.710938-15-15v-56.300781h-30.667969c-22.410156 0-40.640625-18.230469-40.640625-40.640625v-45.667969c0-8.28125 6.71875-15 15-15s15 6.71875 15 15v45.667969c0 5.871094 4.769532 10.640625 10.640625 10.640625h30.667969v-56.308594c0-8.28125 6.710938-15 15-15 8.28125 0 15 6.71875 15 15zm125-35.648438c0 27.929688-22.730469 50.648438-50.660156 50.648438-27.929688 0-50.648438-22.71875-50.648438-50.648438v-71.300781c0-27.929687 22.71875-50.660156 50.648438-50.660156 27.929687 0 50.660156 22.730469 50.660156 50.660156zm117 35.648438c0 8.289062-6.71875 15-15 15-8.289062 0-15-6.710938-15-15v-56.300781h-30.667969c-22.410156 0-40.640625-18.230469-40.640625-40.640625v-45.667969c0-8.28125 6.71875-15 15-15s15 6.71875 15 15v45.667969c0 5.871094 4.769532 10.640625 10.640625 10.640625h30.667969v-56.308594c0-8.28125 6.710938-15 15-15 8.28125 0 15 6.71875 15 15zm0 0" />
        <path d="m0 362.898438c0 25.972656 21.128906 47.101562 47.101562 47.101562h193.898438v30h-96.671875c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h223.34375c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-96.671875v-30h193.898438c25.972656 0 47.101562-21.128906 47.101562-47.101562v-8h-512zm0 0" />
      </svg>
    )
  }
}
